<template>
	<div style="height: 50px;background-color: #ffffff;position: relative;" v-if="isShow">
		<div style="display: inline-block;line-height: 50px;margin-left: 30px;font-size: 14px;">
			<span style="cursor: pointer;color:rgba(136, 136, 136, 1)" @click="toHome">首页</span>
			<span v-for="(item,i) in content">{{item}}</span>
		</div>
		<el-button size="mini" style="position: absolute;top: 12px;right: 12px;" @click="refresh()">刷新
		</el-button>
	</div>
</template>
<script>
	import caches from "@cache/cache.js"
	export default {
		components: {},
		inject: ['reload'],
		data() {
			return {
				isShow: true
			}
		},
		watch: {
			"$route.name": {
				handler(val) {
					if (val == 'platform' || val == 'expert' || val == 'supplier' || val == 'company' || val ==
						'companyJkdp' || val == 'companyNangangJkdp' || val == 'companySanhezhonggongJkdp') {
						this.isShow = false;
					} else {
						this.isShow = true;
					}
				},
				immediate: true,
			},
		},
		props: {
			content: {
				type: Array,
				default () {
					return [];
				}
			}
		},
		methods: {
			toHome() {
				this.$router.push({
					name: caches.role.get()
				})
				this.refresh();
				this.$emit("toHome");
			},
			refresh() {
				this.reload();
			}
		},
	};
</script>
<style lang='scss'>
</style>
