<template>
 <div class="maintain">
      <el-dialog title="高线厂/AB高线/驱动端水平" :visible.sync="dialogVisible" width="30%">
    <div>
      <el-form ref="form"  label-width="80px">
        <el-form-item label="诊断分析：">
           <el-input
              type="textarea"
              :rows="6"
              resize='none'
              placeholder="请输入内容"
              v-model="fmeda"
            >
            </el-input>
        </el-form-item>
        <el-form-item label="处理方式：">
          <el-radio v-model="stat" label="1">维修完成</el-radio>
          <el-radio v-model="stat" label="2">维修失败</el-radio>
        </el-form-item>
        <el-form-item label="备注：">
           <el-input
              type="textarea"
              :rows="6"
              resize='none'
              placeholder="请输入内容"
              v-model="remarks"
            >
            </el-input>
        </el-form-item>
        <el-form-item class="ml-40"> 
             <el-upload
                class="avatar-uploader"
                :action="baseURL + '/base-api/file/upload'"
                list-type="picture-card"
                :on-success="handleAvatarSuccess"
                :show-file-list="false"
                 :on-change="handleChange"
              >
                <img  v-if="imageUrl" :src="imageUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit()">确 定</el-button>
      </span>
  </el-dialog>
 </div>
</template>

<script>
export default {
  props: ["row"],
  watch:{
      row(val) {
      this.unit_id = val.unit_id;
    },
  },
  data() {
    return {
      dialogVisible: false,
      fmeda:"",
      remarks:'',
      imageUrl:"",
      unit_id:"",
      fileList:[],
      stat:'1',
    };
  },
  methods:{
      handleChange(file){
          this.fileList.push(file.uid)
      },
      handleAvatarSuccess(file){
    this.imageUrl = this.photoURL + file.data
  },
  submit(){
      this.timeFrome = {
          unit_id:this.unit_id,
          stat:this.stat,
         imgs : this.fileList,
         fmeda : this.fmeda,
         remarks : this.remarks
      }
     this.$get("/backend-api/web/unit/log/repair", this.timeFrome).then(
        (res) => {
          if ((res.code = 1000)) {
            this.dialogVisible = false;
          }
        }
      );
  }
  }
};
</script>

<style lang='scss'>
.maintain{
    .el-dialog__header {
    background-color: #f5f5f5;
  }
    .el-form-item__label{
        width: 120px !important;
    }
    // .el-textarea__inner{
    //     width: 60% !important;
    // }
    .el-textarea{
        width: 60% !important;
    }
    .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 12px;
    color: #8c939d;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
  }
  .avatar {
    width: 100%;
    height: 100%;
    display: block;
  }
}
</style>