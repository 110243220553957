<template>
  <!-- 日志 -->
  <div class="log">
    <el-dialog title="日志" :visible.sync="dialogVisible" width="60%">
      <div class="top flex jc-between mb-30">
        <div class="title">高线厂/AB高线/驱动端水平</div>

        <div class="flex">
          <div class="flex mr-10">
            <div class="timeText">记录时间：</div>
             <el-date-picker
                v-model="timeValue"
                type="daterange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change='changTime()'
              >
              </el-date-picker>
          </div>
          <div>
            <el-input
              placeholder="请输入内容"
              v-model="text"
              class="input-with-select"
            >
              <el-button slot="append" icon="el-icon-search"></el-button>
            </el-input>
          </div>
        </div>
      </div>
      <div>
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column prop="time" label="时间" align="center">
          </el-table-column>
          <el-table-column prop="op" label="事件" align="center">
          </el-table-column>
          <el-table-column prop="user" label="操作人" align="center">
          </el-table-column>
          <el-table-column prop="name1" label="负责人" align="center">
          </el-table-column>
          <el-table-column prop="remarks" label="处理结果" align="center">
          </el-table-column>
          <el-table-column label="详情" width="500">
            <template slot-scope="scope">
              <div class="flex">
                <div class="textWidth">维修时间：</div>
                <div>{{ scope.row.time }}</div>
              </div>
              <div class="flex">
                <div class="textWidth">诊断分析：</div>
                <div>{{ scope.row.remarks }}</div>
              </div>
              <div class="flex">
                <div class="textWidth">备注：</div>
                <div>{{ scope.row.remarks }}</div>
              </div>
              <div class="flex">
                <div class="textWidth">附件：</div>
                <div class="check">查看</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ["row"],
  watch: {
    row(val) {
      this.timeFrome.unit_id = val.unit_id;
      this.getData()
    },
  },
  data() {
    return {
      dialogVisible: false,
      
      timeFrome:{
       unit_id:"",
      },
      text:"",
      timeValue:"",
      begin_time:"",
      end_time:"",
      tableData: [
      ],
    };
  },
  methods:{
     getData() {
      this.$get("/backend-api/web/unit/log/records", this.timeFrome).then(
        (res) => {
          if ((res.code = 1000)) {
           this.tableData = res.data.records
          }
        }
      );
    },
    changTime(){
      this.begin_time= this.timeValue[0],
        this.end_time= this.timeValue[1]
       let from = {
        begin_time: this.begin_time,
        end_time:this.end_time
       }
       this.timeFrome = Object.assign(this.timeFrome,from)
       this.getData()
    }
  }
};
</script>

<style lang='scss'>
.log {
  .el-table--border th {
    border-right: none;
    background-color: #e6f7ff;
  }
  .el-dialog__header {
    background-color: #f5f5f5;
  }
  .textWidth {
    width: 80px;
    .check {
      color: #72a8f8;
    }
  }
  .timeText{
      width: 110px;
      line-height: 40px;
  }
}
</style>